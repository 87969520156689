<template>
  <div class="d-flex flex-column">
    <top-navbar></top-navbar>
    <router-view  class="flex-fill mt-2"></router-view>
  </div>
</template>

<script>
  import TopNavbar from './TopNavbar.vue'

  export default {
    components: {
      TopNavbar
    }
  }

</script>
<style scoped>
.nw-landing {
  background-image: url('../assets/background-home.png');
  background-repeat: no-repeat;
	background-size: cover;

}
</style>
