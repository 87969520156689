<template>
  <div class="nw-nav nw-nav-border">
    <nav class="navbar navbar-expand-md navbar-light justify-content-between">
      <a class="navbar-brand d-none d-md-block" href="#/user/searchsleep"><img class="nw-logo" src="@/assets/nightware-logo.png" alt="NightWare"/></a>
      <a href="#/user/searchsleep" class="navbar-brand d-md-none"><img class="nw-logo" src="@/assets/nightware-logo.png" alt="NightWare"/></a>
      <a href="#" class="navbar-brand"></a>

      <!--
          Nav Bar Items
      -->
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href="#/admin/groups" :class="$route.path=='/admin/groups' ? 'active' : ''">User Groups</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#/admin/users" :class="$route.path=='/admin/users' ? 'active' : ''">Users</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#/admin/sessions" :class="$route.path=='/admin/sessions' ? 'active' : ''">Sessions</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" href="#/admin/surveys" :class="$route.path=='/admin/surveys' ? 'active' : ''">Surveys</a>
        </li>-->
      </ul>
    </div>

    <!--
        Right hand drop down.
    -->
    <ul class="navbar-nav">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-cog"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#/user/searchsleep">
            <i class="fa fa-search"></i> Sleep Activity
          </a>
          <a class="dropdown-item" href="#/user/searchpatient">
            <i class="fa fa-search"></i> Search Patients
          </a>
          <a class="dropdown-item" href="#/user/participantbatch">
            <i class="fa fa-list"></i> Patient Batches
          </a>
          <a class="dropdown-item" href="#/user/addparticipant">
            <i class="fa fa-plus"></i> Add Patient
          </a>
          <!--<a class="dropdown-item" href="#/user/account">
            <i class="fa fa-user"></i> My Account
          </a>-->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#/admin/dashboard">
            <i class="fa fa-bar-chart"></i> Dashboard
          </a>
          <a class="dropdown-item" href="#/admin/users">
            <i class="fa fa-users"></i> Users
          </a>
          <a class="dropdown-item" href="#/admin/sessions">
            <i class="fa fa-cloud"></i> Sessions
          </a>
          <a class="dropdown-item" href="#/admin/logs">
            <i class="fa fa-list-alt"></i> Logs
          </a>
          <a class="dropdown-item" href="#/admin/batch">
            <i class="fa fa-cog"></i> Batch
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item text-danger" @click="logout()">
            <i class="fa fa-power-off"></i> Log Out
          </a>
        </div>
      </li>
    </ul>
    </nav>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'

export default {
  name: 'Home',
  data () {
      return { logo: process.env.VUE_APP_LOGO }
  },
  methods: {
    toggleLang : function(_lang)
      {
        this.$ml.change(_lang)
        //NetClient.lang = _lang
      },
      logout() {
        NetClient.logOut()
      }
  }

}
</script>
<style scoped>
.nw-nav {
  background-color: #FFFFFF;
}
</style>
